<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="75%"
    >
      <template v-slot:activator="{ on, attrs }">
        <a
          class="white--text pa-1 pa-md-0"
          v-text="'Impressum'"
          v-bind="attrs"
          v-on="on"
          style="margin: 15px;"
          @click="dialogText = impressumText;  showCBConsent = false;"
        >
        <v-responsive
          class="mx-4 shrink hidden-sm-and-down"
          max-height="24"
        >
          <v-divider vertical />
        </v-responsive>
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          class="white--text pa-1 pa-md-0"
          v-text="'Datenschutz'"
          v-bind="attrs"
          v-on="on"
          style="margin: 15px;"
          @click="dialogText = datenschutzText; showCBConsent = true;"
        >
        <v-responsive
          class="mx-4 shrink hidden-sm-and-down"
          max-height="24"
        >
          <v-divider vertical />
        </v-responsive>
        </a>
        <!-- <v-btn
          @click="dialogText = impressumText"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Impressum
        </v-btn>
        <v-btn
          @click="dialogText = datenschutzText"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Datenschutz
        </v-btn> -->
      </template>
      <v-card height="80vh">
        <v-card-title>{{ dialogText == impressumText ? 'Impressum' : 'Datenschutz' }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text
          style="height: 300px;"
        >
          <span
            v-html="dialogText"
          ></span>
          <template>
            <div
              ref="consent"
              class="CBConsentDiv"
            >
            </div>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false; showCBConsent = false;"
          >
            x
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    name: 'BaseDialog',
    data () {
      return {
        dialogm1: '',
        dialog: false,
        showCBConsent: false,
        dialogText: '',
        datenschutzText: '',
        impressumText: '',
      }
    },
    watch: {
      dialogText (val) {
        this.doCookieBot()
      },
    },
    mounted () {
      // this.$nextTick(function () {
      //   console.log('next', this.$refs)
      // })
    },
    methods: {
      doCookieBot: function () {
        // this.$nextTick(function () {
        if (this.showCBConsent === true) {
          if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
            console.log('Its a local server! CookieBot in Footer.vue...consentPage()')
          } else {
            this.$cookiebot.consentPage({
              async: true,
              locale: 'de', // tip: replace 'en' with this.$i18n.locale when using vue-i18n
              ref: this.$refs.consent, // Must be a Vue ref or html element
            })
          }
        }
        this.showCBConsent = false
        // })
      },
    },
    created () {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
      var output = `calc(${height} - ${this.$vuetify.application.top}px)`
      // console.log('CREATED', output, height)

      // GET impressum_for_blackshepherds-net
      fetch('https://datenbank.blackshepherds.net/wp-json/wp/v2/pages?slug=impressum_for_blackshepherds-net&password=maxmax')
        .then(async response => {
          const data = await response.json()

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText
            return Promise.reject(error)
          }

          this.impressumText = data[0].content.rendered
          // console.log('impressumText', this.impressumText)
        })
        .catch(error => {
          this.errorMessage = error
          console.error('There was an error!', error)
        })

      // GET datenschutz_for_blackshepherds-net
      fetch('https://datenbank.blackshepherds.net/wp-json/wp/v2/pages?slug=datenschutz_for_blackshepherds-net&password=maxmax')
        .then(async response => {
          const data = await response.json()

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText
            return Promise.reject(error)
          }

          this.datenschutzText = data[0].content.rendered
          // console.log('Datenschutz Text', this.datenschutzText)
        })
        .catch(error => {
          this.errorMessage = error
          console.error('There was an error!', error)
        })
    },
  }
</script>
