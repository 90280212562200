import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'

import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'

Vue.use(VueCookieBot, {
  cookieBotID: '83b69e94-4a5d-4728-a9ed-b9977445eae7',
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
